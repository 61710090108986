import { mergeClasses } from "@fluentui/react-components";

const groupBy = (arr, property) => {
    return arr.reduce((acc, cur) => {
        acc[cur[property]] = [...acc[cur[property]] || [], cur];
        return acc;
    }, {});
}

const getChatRowStyle = (voiceMetricsCount, classes, SLACount) => {
    if ((voiceMetricsCount === 0 || voiceMetricsCount === 4) && SLACount > 0)
        return mergeClasses(classes.chatRow, classes.chatRowPositionTwoUp)
    else if (SLACount > 0)
        return mergeClasses(classes.chatRow, classes.chatRowPositionUp)
    else
        return classes.chatRow

}
const sortByProperty = (property) => {
    return function (a, b) {
        if (a[property] > b[property])
            return 1;
        else if (a[property] < b[property])
            return -1;

        return 0;
    }
}

const sliceArray = (arr, size) => {
    var slicedArray = [];
    for (var i = 0; i < arr.length; i += size) {
        slicedArray.push(arr.slice(i, i + size));
    }
    return slicedArray;
}


const secondsTomin = (d, flag) => {
    if (d !== "-") {
        d = Math.abs(d)
        d = Number(d);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        var mDisplay = m > 0 ? (m < 10 ? "0" : "") + m + ":" : "00:";
        var sDisplay = s > 0 ? (s < 10 ? "0" : "") + s : "00";
        if (flag)
            return mDisplay + sDisplay + " min";
        return mDisplay + sDisplay;
    } else {
        return "-"
    }
}

export {
    groupBy,
    getChatRowStyle,
    sortByProperty,
    sliceArray,
    secondsTomin
}