const SessionStorageHelper = (function () {
  var instance;
  function getInstance() {
    if (!instance) {
      instance = this;
      return instance;
    }
    return instance;
  }
  function setToken(tokenObj) {
    sessionStorage.setItem("accessToken", JSON.stringify(tokenObj.accessToken));
    sessionStorage.setItem(
      "refreshToken",
      JSON.stringify(tokenObj.refreshToken)
    );
    sessionStorage.setItem("idToken", JSON.stringify(tokenObj.idToken));
  }
  function setTokenByRefreshToken(tokenObj) {
    let accessToken = JSON.parse(sessionStorage.getItem("accessToken"))
    accessToken.jwtToken = tokenObj.access_token
    sessionStorage.setItem('accessToken', JSON.stringify(accessToken))

    let IDToken = JSON.parse(sessionStorage.getItem("idToken"))
    IDToken.jwtToken = tokenObj.id_token
    sessionStorage.setItem('idToken', JSON.stringify(IDToken))
  }
  function getAccessToken() {
    return JSON.parse(sessionStorage.getItem("accessToken"));
  }
  function getRefreshToken() {
    return JSON.parse(sessionStorage.getItem("refreshToken"));
  }
  function getIDToken() {
    return JSON.parse(sessionStorage.getItem("idToken"));
  }
  function getAccessTokenId() {
    return getAccessToken().jwtToken;
  }
  function getRefreshTokenId() {
    return getRefreshToken().token;
  }
  function getIDTokenId() {
    return getIDToken().jwtToken;
  }

  function clearToken() {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("idToken");
  }

  function setLastLoginTime(lastLogin) {
    sessionStorage.setItem("lastLogin", lastLogin);
  }
  function getLastLoginTime() {
    return sessionStorage.getItem("lastLogin");
  }


  function setDefaultApp(defaultApp) {
    sessionStorage.setItem("defaultApp", defaultApp);
  }
  function getDefaultApp() {
    return sessionStorage.getItem("defaultApp");
  }

  function setSigninCount(signinCount) {
    sessionStorage.setItem("signinCount", signinCount);
  }
  function getSigninCount() {
    return sessionStorage.getItem("signinCount");
  }
  function setUIScope(UIScope) {
    return sessionStorage.setItem("UIScope", UIScope)
  }
  function setRoutingProfile(routingProfile) {
    sessionStorage.setItem("routingProfile", routingProfile);
  }
  function getRoutingProfile() {
    return sessionStorage.getItem("routingProfile");
  }
  function isRoutingProfileCached() {
    var routingProfile = sessionStorage.getItem("routingProfile");
    return (!!routingProfile && routingProfile.length > 0)
  }
  function setSecurityProfile(securityProfile) {
    sessionStorage.setItem("securityProfile", securityProfile);
  }
  function getSecurityProfile() {
    return sessionStorage.getItem("securityProfile");
  }
  function isSecurityProfileCached() {
    var securityProfile = sessionStorage.getItem("securityProfile");
    return (!!securityProfile && securityProfile.length > 0)
  }
  function sethierarchyLevel(hierarchyLevel) {
    sessionStorage.setItem("hierarchyLevel", hierarchyLevel);
  }
  function gethierarchyLevel() {
    return sessionStorage.getItem("hierarchyLevel");
  }
  function ishierarchyLevelCached() {
    var hierarchyLevel = sessionStorage.getItem("hierarchyLevel");
    return (!!hierarchyLevel && hierarchyLevel.length > 0)
  }
  function setRetryErrorFlag(flag) {
    sessionStorage.setItem("retryErrorFlag", flag);
  }
  function getRetryErrorFlag() {
    return sessionStorage.getItem("retryErrorFlag");
  }
  return {
    getInstance: getInstance,
    setToken: setToken,
    getAccessToken: getAccessToken,
    getRefreshToken: getRefreshToken,
    getIDToken: getIDToken,
    clearToken: clearToken,
    getAccessTokenId: getAccessTokenId,
    getRefreshTokenId: getRefreshTokenId,
    getIDTokenId: getIDTokenId,
    setLastLoginTime: setLastLoginTime,
    getLastLoginTime: getLastLoginTime,
    setTokenByRefreshToken: setTokenByRefreshToken,
    setDefaultApp: setDefaultApp,
    getDefaultApp: getDefaultApp,
    setSigninCount: setSigninCount,
    getSigninCount: getSigninCount,
    setUIScope: setUIScope,
    setRoutingProfile: setRoutingProfile,
    getRoutingProfile: getRoutingProfile,
    isRoutingProfileCached: isRoutingProfileCached,
    setSecurityProfile: setSecurityProfile,
    getSecurityProfile: getSecurityProfile,
    isSecurityProfileCached: isSecurityProfileCached,
    sethierarchyLevel: sethierarchyLevel,
    gethierarchyLevel: gethierarchyLevel,
    ishierarchyLevelCached: ishierarchyLevelCached,
    setRetryErrorFlag: setRetryErrorFlag,
    getRetryErrorFlag: getRetryErrorFlag,
  };
})();

export default SessionStorageHelper;
