import './WallboardApp.css';
import ConfigHelper from "./helpers/ConfigHelper";
import { useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import Wallboard from "./components/Wallboard";
import SessionStorageHelper from "../src/helpers/SessionStorageHelper";
import logo from "./images/Logo.png"
import { ScreenClassProvider } from 'react-grid-system';

import {
  Route,
  Routes
} from "react-router-dom";
import "./WallboardApp.css"

import {
  Label, makeStyles,
  Spinner
} from "@fluentui/react-components";

const sessionStorageHelper = SessionStorageHelper.getInstance();



// styling starts 
const useStyles = makeStyles({
  rootLabel: {
    backgroundColor: '#004578',
    display: 'flex',
    minHeight: '50px',
    alignItems: 'center'

  },
  rootContent: {
    marginLeft: '20px',
    color: 'white',
    fontSize: '2em'
  },
  rootLogo: {
    height: '30px',
    marginLeft: '30px'
  }
});


function WallboardApp() {
  const classes = useStyles();
  const [loggedin, setloggedin] = useState(false);
  useEffect(() => {
    try {
      const awsConfig = ConfigHelper.getAWSConfig();
      const oauth = ConfigHelper.getOAuthConfig();
      Amplify.configure(awsConfig);
      Auth.configure({ oauth });
    }
    catch (error) {
      console.error('error in amplify')
    }

    // check the current user when the App component is loaded
    Auth.currentAuthenticatedUser()
      .then((user) => {
        sessionStorageHelper.setRetryErrorFlag(0);
        sessionStorageHelper.setToken(user.signInUserSession);
        setloggedin(true);

      })
      .catch((e) => {
        if ("The user is not authenticated" === e) {
          setloggedin(false);
          if (window.location.pathname === '/logout') {
            this.forceUpdate();
          }
          else {
            window.location.replace(ConfigHelper.getAuthURL());
          }
        }
      });
  }, []);


  return (
    <ScreenClassProvider>
      <Label className={classes.rootLabel}><img className={classes.rootLogo} src={logo} alt="logo" /><span className={classes.rootContent}>WallBoard</span></Label>
      {!loggedin ? <div className="maindiv"><Spinner labelPosition="after" label="Loading..." /></div> :
        <div>
          <Routes>
            <Route
              className="Wallboard"
              exact
              path="/Wallboard/:tenant/:wallboardname/:version"
              element={<Wallboard />}
            />
            <Route
              className="Wallboard"
              exact
              path="/Wallboard/:tenant/:wallboardname"
              element={<Wallboard />}
            />
          </Routes></div>}
      <div className="footerWallBd">Copyright ®2023 Accenture - all rights reserved</div>
    </ScreenClassProvider>
  );
}

export default WallboardApp;
