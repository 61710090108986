
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { wallboardStyles } from "../fluentStyles/wallboardStyles"
import "../styles/Wallboard.css";
import { Container, Row, Col } from 'react-grid-system';
import {
    Divider,
    mergeClasses,
    Spinner,
    Card,
    CardHeader

} from "@fluentui/react-components";
import CustomTextProgressbar from "./CustomTextProgressbar";
import {
    CallRegular,
    ArrowUpRightFilled,
    CaretDownFilled,
    CaretUpFilled,
    TimerRegular,
    CommentRegular,
    ClockRegular,
    PersonClockRegular,
    ClipboardClockRegular,
    CallEndRegular,
    HeadsetRegular
} from "@fluentui/react-icons";

import 'react-circular-progressbar/dist/styles.css';
import HTTPClient from "../helpers/HTTPClient";

import { groupBy, getChatRowStyle, secondsTomin, sliceArray, sortByProperty } from "../helpers/Util"

function constructDescriptiveMetricKey(metricData) {
    // If it has a threshold value then add it to the object key
    return metricData.threshold ? metricData.metricCode + '_' + metricData.threshold + '_' + metricData.channel : metricData.metricCode + '_' + metricData.channel
}

function Wallboardtest() {

    //Get the id from path parms 
    let { wallboardname, tenant, version } = useParams();
    // fluent UI style class import
    const classes = wallboardStyles();
    let metricConfigValue = []
    const [loadingFlag, setloadingFlag] = useState(true);
    const [metricsValue, setmetricsValue] = useState();
    const [voiceMetricsCount, setvoiceMetricsCount] = useState(0);
    const [viewWallboardArray, setviewWallboardArray] = useState();
    const [SLACount, setSLACount] = useState(0);
    const [chatArray, setChatArray] = useState();
    const metricDefaultData = {
        AVG_HANDLE_TIME_VOICE: {
            "headerIcon": "CallRegular",
            "bodyIcon": "TimerRegular"
        },
        AGENTS_ONLINE_VOICE: {
            "headerIcon": "CallRegular",
            "bodyIcon": "HeadsetRegular"
        },
        CONTACTS_IN_QUEUE_CHAT: {},
        AVG_HOLD_TIME_VOICE: {
            "headerIcon": "CallRegular",
            "bodyIcon": "ClockRegular"
        },
        OLDEST_CONTACT_AGE_VOICE: {
            "headerIcon": "CallRegular",
            "bodyIcon": "PersonClockRegular"
        },
        CONTACTS_ABANDONED_VOICE: {
            "headerIcon": "CallRegular",
            "bodyIcon": "CallEndRegular"
        },
        CONTACTS_HANDLED_VOICE: {
            "headerIcon": "CallRegular",
            "bodyIcon": "ArrowUpRightFilled"
        },
        SERVICE_LEVEL_60_VOICE: {
        },
        SERVICE_LEVEL_30_VOICE: {
        },
        AVG_HOLD_TIME_CHAT: {},
        CONTACTS_IN_QUEUE_VOICE: {
            "headerIcon": "CallRegular",
            "bodyIcon": "ClipboardClockRegular"
        }
    }


    useEffect(() => {
        getMetrics();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    const getMetrics = () => {
        let autoRefreshTime = 100000;
        let url = process.env.REACT_APP_ADMIN_CONSOLE_WALLBOARD_REST_API_URL + `${wallboardname}`
        if (version) {
            url = `${url}?version=${version}`
        }
        HTTPClient.get(url,
            {
                headers: {
                    tenantId: tenant
                },
            }
        )
            .then(response => {
                if (response.status === 200 && response.data) {
                    metricConfigValue = response.data.metrics
                    autoRefreshTime = ((response.data.autoRefreshRate) * 1000)
                    let channelArray = groupBy(metricConfigValue, "channel")
                    let voiceChannelArray = channelArray["VOICE"] !== undefined ? channelArray["VOICE"] : []
                    let chatChannelArray = channelArray["CHAT"] !== undefined ? channelArray["CHAT"] : []
                    let chatViewArray = (groupBy(chatChannelArray, "viewWallboard"))["true"]
                    chatViewArray !== undefined ? setChatArray(chatViewArray) : setChatArray([])
                    let viewArray = []
                    viewArray = (groupBy(voiceChannelArray, "viewWallboard"))["true"] !== undefined? (groupBy(voiceChannelArray, "viewWallboard"))["true"]: []
                    let SLA = []
                    SLA = viewArray !== undefined ? groupBy(viewArray, "unit")["percentage"] : []
                    let voiceMetricCount;
                    let SLAcount = 0;
                    if (SLA !== undefined) {
                        SLAcount = SLA.length
                    }
                    setSLACount(SLAcount)
                    if (viewArray.length > 0) {
                        voiceMetricCount = viewArray.length - SLAcount;
                        viewArray.sort(sortByProperty("displaySequence"))
                    }
                    if (SLAcount > 1)
                        viewArray.splice(-1);

                    setviewWallboardArray(viewArray)
                    setvoiceMetricsCount(voiceMetricCount)

                    let metric = [];

                    metricConfigValue.forEach(function (metricConfig) {
                        metric[constructDescriptiveMetricKey(metricConfig)] = validateMetric(metricConfig)
                    });
                    setmetricsValue(metric)
                    setloadingFlag(false);
                    const timer = setTimeout(() => {
                        getMetrics();
                    }, autoRefreshTime);
                    return () => clearTimeout(timer);
                } else {
                }
            })
            .catch(error => {
                console.error('Error here - put', error)
            })


    }

    const validateMetric = (metricData) => {
        let metricStyle = {}
        metricData.currentValue = checkForValues(metricData.currentValue)
        metricData.comparisonValue = checkForValues(metricData.comparisonValue)
        if (metricData.upper < metricData.currentValue) {
            metricStyle.card = mergeClasses(classes.card, classes.errorCard)
            metricStyle.metricBody = mergeClasses(classes.metricBody, classes.colorWhite)
            metricStyle.primaryFill = "white"
            metricStyle.yesterdayValueItem = mergeClasses(classes.yesterdayValueItem, classes.colorWhite)
            /**Temporary code which will be changed according to requirement in future PI */
            if (metricData.metricCode.includes("SERVICE_LEVEL")) {
                metricStyle.display = mergeClasses(classes.SLAandChatQError)
                metricStyle.CircularWordings = mergeClasses(classes.circularError)
                metricStyle.progressBar = { stroke: "#A4262C" }
                metricStyle.primaryFill = "#005A9E"
            }
            if (metricData.channel === "CHAT") {
                metricStyle.ChatQ = mergeClasses(classes.ChatQError)
                metricStyle.primaryFill = "#A4262C"
                metricStyle.currentValueCQ = mergeClasses(classes.currentValueCQError)
            }
        } else if (metricData.lower < metricData.currentValue && metricData.upper >= metricData.currentValue) {
            metricStyle.card = mergeClasses(classes.card, classes.warningCard)
            metricStyle.metricBody = mergeClasses(classes.metricBody, classes.colorBlack)
            metricStyle.primaryFill = "Black"
            metricStyle.yesterdayValueItem = mergeClasses(classes.yesterdayValueItem, classes.colorBlack)
            metricStyle.customLineColor = mergeClasses(classes.blackCustomLineColor)
            if (metricData.metricCode.includes("SERVICE_LEVEL")) {
                metricStyle.display = mergeClasses(classes.SLAandChatQWarning)
                metricStyle.CircularWordings = mergeClasses(classes.circularWarning)
                metricStyle.progressBar = { stroke: "#FF8C00" }
            }
            if (metricData.channel === "CHAT") {
                metricStyle.ChatQ = mergeClasses(classes.ChatQWarning)
                metricStyle.primaryFill = "#FF8C00"
                metricStyle.currentValueCQ = mergeClasses(classes.currentValueCQWarn)
            }
        } else {
            metricStyle.card = classes.card
            metricStyle.metricBody = classes.metricBody
            metricStyle.primaryFill = "#005A9E"
            metricStyle.yesterdayValueItem = mergeClasses(classes.yesterdayValueItem, classes.colorGreen)
            if (metricData.metricCode.includes("SERVICE_LEVEL")) {
                metricStyle.display = mergeClasses(classes.SLAandChatQ)
                metricStyle.CircularWordings = mergeClasses(classes.circular)
                metricStyle.progressBar = { stroke: "rgb(0, 69, 140)" }
            }
            if (metricData.channel === "CHAT") {
                metricStyle.ChatQ = mergeClasses(classes.outboundIcon)
                metricStyle.currentValueCQ = mergeClasses(classes.currentValueCQ)
            }
        }
        metricData["styles"] = metricStyle
        metricData["metricDefaultData"] = metricDefaultData[constructDescriptiveMetricKey(metricData)]
        return metricData;
    }

    const checkForValues = (inputValue) => {
        if (inputValue === -1) {
            inputValue = "-"
            return inputValue
        }
        else {
            return inputValue
        }
    }

    let BuildIcon = (iconProps) => {
        switch (iconProps.icon) {
            case "CallRegular":
                return <CallRegular className={iconProps.class} primaryFill={iconProps.primaryFill} />
            case "ClockRegular":
                return <ClockRegular className={iconProps.class} primaryFill={iconProps.primaryFill} />
            case "ClipboardClockRegular":
                return <ClipboardClockRegular className={iconProps.class} primaryFill={iconProps.primaryFill} />
            case "PersonClockRegular":
                return <PersonClockRegular className={iconProps.class} primaryFill={iconProps.primaryFill} />
            case "TimerRegular":
                return <TimerRegular className={iconProps.class} primaryFill={iconProps.primaryFill} />
            case "CallEndRegular":
                return <CallEndRegular className={iconProps.class} primaryFill={iconProps.primaryFill} />
            case "HeadsetRegular":
                return <HeadsetRegular className={iconProps.class} primaryFill={iconProps.primaryFill} />
            case "ArrowUpRightFilled":
                return <ArrowUpRightFilled className={iconProps.class} primaryFill={iconProps.primaryFill} />
            default:
                return <div></div>
        }
    }


    let BuildEmptyCol = (data) => {
        let col = [];
        for (let i = data.colLength; i < 4; i++) {
            col.push(< Col sm={3} className="col" key={i}></Col>)
        }
        return col;
    }

    let BuildSLA = (metric) => {
        return (
            <Card className={mergeClasses(classes.card, classes.slaSectionCount)}>
                <CardHeader
                    header={<div className="cardHeader"><TimerRegular className={classes.callIcon} primaryFill="#005A9E"></TimerRegular><span className="titleSize">Service Level Agreement (SLA)</span></div>}
                />
                <Divider className={mergeClasses(classes.customLineColor, classes.slaDivider)}></Divider>
                <div className={classes.card} >
                    {metricsValue.SERVICE_LEVEL_30_VOICE.viewWallboard ?
                        <>
                            {SLACount === 1 ? <div className="slaBar20 mrTp10">
                                <CustomTextProgressbar metric={metricsValue.SERVICE_LEVEL_30_VOICE} wording={"In 30 Seconds"}>
                                </CustomTextProgressbar>
                            </div> : <div className="slaBar20">
                                <CustomTextProgressbar metric={metricsValue.SERVICE_LEVEL_30_VOICE} wording={"In 30 Seconds"}>
                                </CustomTextProgressbar>
                            </div>}
                        </>
                        : ""}
                    {SLACount > 1 ? <Divider className="slaSeparator"></Divider> : ""}
                    {metricsValue.SERVICE_LEVEL_60_VOICE.viewWallboard ?
                       <>
                        { SLACount === 1 ? <div className="slaBar30 mrTp11">
                            <CustomTextProgressbar metric={metricsValue.SERVICE_LEVEL_60_VOICE} wording={"In 60 Seconds"}>
                            </CustomTextProgressbar>
                        </div> : <div className="slaBar30">
                            <CustomTextProgressbar metric={metricsValue.SERVICE_LEVEL_60_VOICE} wording={"In 60 Seconds"}>
                            </CustomTextProgressbar>
                        </div>}
                        </>
                         : ""}
                </div>
            </Card>)
    }

    let BuildCard = (metric) => {
        const name = metric.name;
        return (
            <Card className={metricsValue[name].styles.card} >
                <CardHeader
                    header={<div className="cardHeader"><BuildIcon icon={metricsValue[name].metricDefaultData.headerIcon} class={classes.callIcon} primaryFill={metricsValue[name].styles.primaryFill} /><span className="titleSize">{metricsValue[name].displayName}</span></div>}
                />
                <Divider className={mergeClasses(metricsValue[name].styles.customLineColor, classes.firstDivider)}></Divider>
                <div className="cardBody">
                    <BuildIcon icon={metricsValue[name].metricDefaultData.bodyIcon} class={classes.outboundIcon}  primaryFill={metricsValue[name].styles.primaryFill} />
                    <div className="metricBody">
                        <span className={metricsValue[name].hasComparison && metricsValue[name].viewComparison ? "currentValueStyle" : "currentValueStyleMargin"}>{metricsValue[name].unit === "seconds" ? secondsTomin(metricsValue[name].currentValue, true) : metricsValue[name].currentValue}</span>
                        {metricsValue[name].hasComparison && metricsValue[name].viewComparison ?
                            <div><Divider className={mergeClasses(metricsValue[name].styles.customLineColor, classes.secondDivider)}></Divider>
                                <span className="cardBody">
                                    <p className="yesterdayAlignment">to yesterday</p>
                                    {metricsValue[name].yesterdayValue > 1 ? <CaretUpFilled className={classes.caretIcons} primaryFill={metricsValue[name].primaryFill} />
                                        :
                                        <CaretDownFilled className={classes.caretIcons} primaryFill={metricsValue[name].primaryFill} />}
                                    <span className={metricsValue[name].styles.yesterdayValueItem}>{metricsValue[name].unit === "seconds" ? secondsTomin(metricsValue[name].comparisonValue, false) : metricsValue[name].comparisonValue}
                                    </span>
                                </span> </div> : ""}
                    </div>

                </div>
            </Card>)
    }

    let Uirender = () => {

        return (
            <>
                {sliceArray(viewWallboardArray, 4).map(
                    (slice, i) =>
                        <Row className="row" key={i}>
                            {slice.map((item, i) => (
                                ((item.metricCode).includes("SERVICE_LEVEL")) ?
                                    <>
                                        <BuildEmptyCol colLength={slice.length} />
                                        < Col sm={3} className="col" key={i}>
                                            <BuildSLA />
                                        </Col></> :
                                    < Col sm={3} className="col" key={i}>
                                        <BuildCard name={item.metricCode + '_' + item.channel} />
                                    </Col>)
                            )}
                        </Row >
                )
                }
                {chatArray.length > 0 ?
                    <Row className={getChatRowStyle(voiceMetricsCount, classes, SLACount)} key="SLA">
                        <Col sm={9} className="col" key={metricsValue.AVG_HOLD_TIME_CHAT.metricCode + '_' + metricsValue.AVG_HOLD_TIME_CHAT.channel}>
                            <Card className={mergeClasses(classes.card, classes.chatQueueSection)} appearance="filled-alternative">
                                <CardHeader
                                    header={<div className="cardHeader"><CommentRegular className={classes.callIcon} primaryFill="#78abcc"></CommentRegular><span className="titleSize">Chat Queue</span></div>}
                                />
                                <Divider className={metricsValue.AVG_HOLD_TIME_CHAT.styles.customLineColor}></Divider>
                                <div className="chatQueueDiv">
                                    {chatArray.map((item, i) =>
                                    (((item.metricCode).includes("AVG_HOLD_TIME")) ?
                                    <>
                                        {chatArray.length ===1?
                                        <div className="watingTimeCardBody mrLft15">
                                            <ClockRegular className={classes.outboundIcon} primaryFill={metricsValue.AVG_HOLD_TIME_CHAT.styles.primaryFill} />
                                            <div className="metricBodyChatQueue">
                                                <span className="titleSize">Waiting Time</span>
                                                <span className="cardBodyCQ">
                                                    <p className={metricsValue.AVG_HOLD_TIME_CHAT.styles.currentValueCQ}>{secondsTomin(metricsValue.AVG_HOLD_TIME_CHAT.currentValue, true)}</p> </span>
                                            </div> </div>:<div className="watingTimeCardBody">
                                            <ClockRegular className={classes.outboundIcon} primaryFill={metricsValue.AVG_HOLD_TIME_CHAT.styles.primaryFill} />
                                            <div className="metricBodyChatQueue">
                                                <span className="titleSize">Waiting Time</span>
                                                <span className="cardBodyCQ">
                                                    <p className={metricsValue.AVG_HOLD_TIME_CHAT.styles.currentValueCQ}>{secondsTomin(metricsValue.AVG_HOLD_TIME_CHAT.currentValue, true)}</p> </span>
                                            </div> </div>}
                                            </>
                                             : ""))}
                                    {chatArray.length > 1 ? <Divider vertical className="verticalDivider"></Divider> : ""}
                                    {chatArray.map((item, i) =>
                                    (((item.metricCode).includes("CONTACTS_IN_QUEUE")) ?
                                    <>
                                        {chatArray.length ===1?
                                        <div className="cardBody mrLft15">
                                            <PersonClockRegular className={classes.outboundIcon} primaryFill={metricsValue.CONTACTS_IN_QUEUE_CHAT.styles.primaryFill} />
                                            <div className="metricBodyChatQueue">
                                                <span className="titleSize">Contact Waiting</span>
                                                <span className="cardBodyCQ">
                                                    <p className={metricsValue.CONTACTS_IN_QUEUE_CHAT.styles.currentValueCQ}>{metricsValue.CONTACTS_IN_QUEUE_CHAT.currentValue}</p>
                                                </span> </div> </div> : <div className="cardBody">
                                                <PersonClockRegular className={classes.outboundIcon} primaryFill={metricsValue.CONTACTS_IN_QUEUE_CHAT.styles.primaryFill} />
                                            <div className="metricBodyChatQueue">
                                                <span className="titleSize">Contact Waiting</span>
                                                <span className="cardBodyCQ">
                                                    <p className={metricsValue.CONTACTS_IN_QUEUE_CHAT.styles.currentValueCQ}>{metricsValue.CONTACTS_IN_QUEUE_CHAT.currentValue}</p>
                                                </span> </div> </div>}
                                                </>
                                                : ""))}
                                </div>
                            </Card>
                        </Col>
                        <Col sm={3} className="col" key="emptychat"></Col>
                    </Row> : ""}
            </>
        );
    }
    return (
        <Container className="container">
            <Row>
                <Col sm={12} key="main">
                    <div className="mainLabel">Welcome to your Wallboard!</div>
                </Col>
            </Row>
            {loadingFlag ? <Spinner labelPosition="after" label="Loading..." /> :
                <Uirender />}
        </Container>


    );

}

export default Wallboardtest;
