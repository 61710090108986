const ConfigHelper = (function () {
  function getAWSConfig() {
    var config = {};
    //let envVariable = JSON.parse(sessionStorage.getItem('cognitoParams'))
    config.region = process.env.REACT_APP_WALLBOARD_REGION;
    config.userPoolId = process.env.REACT_APP_WALLBOARD_USER_POOL_ID;
    config.userPoolWebClientId = process.env.REACT_APP_WALLBOARD_USER_POOL_WEBCLIENT_ID;
    config.mandatorySignIn = false;

    var cookie = {};
    cookie.domain = window.location.host;
    cookie.path = window.location.host;
    cookie.expires = 365;
    cookie.httpOnly = true;
    cookie.secure = true;

    //config.cookieStorage = cookie;
    var redirectSignInURL = `${window.location.origin}/`
    var redirectSignOutURL = `${redirectSignInURL}logout`
    config.redirectSignIn = redirectSignInURL;
    config.redirectSignOut = redirectSignOutURL
    var awsConfig = {};
    awsConfig.Auth = config;

    return awsConfig;
  }

  function getOAuthConfig() {
    var redirectSignInURL = `${window.location.origin}/`
    var redirectSignOutURL = `${redirectSignInURL}logout`
    //let envVariable = JSON.parse(sessionStorage.getItem('cognitoParams'))
    var oauth = {};
    //oauth.domain = process.env.REACT_APP_ADMIN_CONSOLE_COGNITO_DOMAIN;
    oauth.domain = process.env.REACT_APP_WALLBOARD_DOMAIN;
    oauth.scope = [
      process.env.REACT_APP_WALLBOARD_COGNITO_SCOPE_OPENID,
      process.env.REACT_APP_WALLBOARD_COGNITO_SCOPE_USER,
    ];
    oauth.redirectSignIn = redirectSignInURL
    oauth.redirectSignOut = redirectSignOutURL
    oauth.responseType =
      process.env.REACT_APP_WALLBOARD_COGNITO_RESPONSE_TYPE;
    return oauth;
  }

  function getAuthURL() {
    //let envVariable = JSON.parse(sessionStorage.getItem('cognitoParams'))
    var redirectSignInURL = `${window.location.origin}/`
    return `https://${process.env.REACT_APP_WALLBOARD_DOMAIN}/oauth2/authorize?client_id=${process.env.REACT_APP_WALLBOARD_USER_POOL_WEBCLIENT_ID}&response_type=${process.env.REACT_APP_WALLBOARD_COGNITO_RESPONSE_TYPE}&scope=${process.env.REACT_APP_WALLBOARD_COGNITO_SCOPE_USER}+${process.env.REACT_APP_WALLBOARD_COGNITO_SCOPE_OPENID}&redirect_uri=${redirectSignInURL}`
    // return `https://${process.env.REACT_APP_ADMIN_CONSOLE_COGNITO_DOMAIN}/oauth2/authorize?client_id=${process.env.REACT_APP_ADMIN_CONSOLE_USER_POOL_WEBCLIENT_ID}&response_type=${process.env.REACT_APP_ADMIN_CONSOLE_COGNITO_RESPONSE_TYPE}&scope=${process.env.REACT_APP_ADMIN_CONSOLE_COGNITO_SCOPE_USER}+${process.env.REACT_APP_ADMIN_CONSOLE_COGNITO_SCOPE_OPENID}&redirect_uri=${redirectSignInURL}`
  }

  return {
    getAWSConfig: getAWSConfig,
    getOAuthConfig: getOAuthConfig,
    getAuthURL: getAuthURL,
  };
})();

export default ConfigHelper;
