import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { wallboardStyles } from "../fluentStyles/wallboardStyles"
import "../styles/Wallboard.css";
import {
    Divider,
    mergeClasses

} from "@fluentui/react-components";
function CustomTextProgressbar(props) {
    const { children, ...otherProps } = props;
    const classes = wallboardStyles();
    // const designStyle = props.design
    let percentage = props.metric.currentValue !== "-" ? (Math.round(props.metric.currentValue)) : "0"
    let design = props.metric.styles.progressBar

    return (
        <div>
            <CircularProgressbarWithChildren value={percentage} circleRatio={0.5} strokeWidth={13}
                styles={{
                    root: {
                        transform: "rotate(0.75turn)",
                    },
                    path: design,
                    trail: {
                        // Trail color
                        stroke: '#abdbe3',


                    },
                    text: {
                        // Text color
                        fill: 'darkblue',
                        // Text size
                        fontSize: '16px',
                        transform: 'rotate(0.25turn)',
                        transformOrigin: 'center center',
                    }
                }}
                {...otherProps}
            >
                <div className="textAlignCentre">
                    <div className={props.metric.styles.display}>{props.metric.currentValue !== "-" ? (`${(Math.round(props.metric.currentValue))}%`) : <span className="noValue">-</span>}</div>
                    <Divider className={mergeClasses(classes.customLineColor, classes.slaDivider, classes.slaCircularBarAlignment)}></Divider>
                    <div className="circularWordings">{props.wording}</div>
                </div>
            </CircularProgressbarWithChildren>
            {props.children}
        </div>
    );
}

export default CustomTextProgressbar;