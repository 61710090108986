import {
    makeStyles, shorthands,
    tokens

} from "@fluentui/react-components";

const wallboardStyles = makeStyles({
    card: {
        // width: "23vw",
        maxWidth: "100%",
        height: "33vh",
        backgroundColor: 'white',
        ...shorthands.padding(0),
        position: 'unset',

    },
    warningCard: {
        backgroundColor: "#FF8C00",
        color: "#000000"
    },
    errorCard: {
        backgroundColor: "#A4262C",
        color: "#FFFFFF"
    },
    colorWhite: {
        color: "#FFFFFF"
    },
    colorBlack: {
        color: "#000000"
    },
    colorGreen: {
        color: "#107c10"
    },
    chatQueueSection: {

        width: "100%"
    },
    slaSection: {
        height: "66vh",
        marginLeft: "auto",
    },
    slaSectionDynamic: {
        height: "69vh",
        marginLeft: "auto",
        // position: "absolute",
        // top: "50.2vh",
        // width: "23%",
    },
    slaSectionCount: {
        height: "69vh",
        marginLeft: "auto",
        // position: "absolute",
        // top: "19.7vh",
        // width: "23%",
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },

    text: {
        ...shorthands.margin(0),
    },
    callIcon: {
        width: "3.8vh",
        height: "4.8vh",
        paddingRight: "5px"

    },
    outboundIcon: {
        width: "5vw",
        height: "14vh",
        marginRight: "1vh",
        marginLeft:"8px"
    },
    caretIcons: {
        height: "auto",
        fontSize: "1.5em"
    },
    customLineColor: {
        "::before": {
            borderTopWidth: "1px",
        },
        "::after": {
            borderTopWidth: "1px",
        },
    },
    blackCustomLineColor: {
        "::before": {
            ...shorthands.borderColor("black"),
        },
        "::after": {
            ...shorthands.borderColor("black"),
        },
    },
    slaDivider: {
        maxHeight: "3px"
    },
    metricBody: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "20px"
    },

    yesterdayValueItem: {
        display: "flex",
        alignItems: "center",
        fontSize: "1.5em"
    },
    dividerlength: {
        width: "75%",
        marginLeft: "5vh"
    },
    slaCircularBarAlignment: {
        width: "100%",
    },
    chatRow: {
        paddingTop: "0px",
        marginTop: "2vh"
    },
    chatRowPositionTwoUp: {
        marginTop: "-69vh"
    },
    chatRowPositionUp: {
        marginTop: "-33vh"
    },
    SLAandChatQError: {
        color: "#A4262C",
        fontWeight: "500",
        fontSize: "26px",
        paddingBottom: "10px"
    },
    SLAandChatQWarning: {
        color: "#FF8C00",
        fontWeight: "500",
        fontSize: "26px",
        paddingBottom: "10px"
    },
    SLAandChatQ: {
        fontWeight: "500",
        fontSize: "26px",
        paddingBottom: "10px"
    },
    circularError: {
        marginLeft: "6vh",
        fontSize: "0.8em",
        color: "#A4262C"
    },
    circularWarning: {
        marginLeft: "6vh",
        fontSize: "0.8em",
        color: "#FF8C00"
    },
    circular: {
        marginLeft: "6vh",
        fontSize: "0.8em"
    },
    currentValueCQ: {
        fontSize: "5.6vh",
        marginTop: "1vh"
    },
    currentValueCQError: {
        fontSize: "5.6vh",
        marginTop: "1vh",
        color: "#A4262C"
    },
    currentValueCQWarn: {
        fontSize: "5.6vh",
        marginTop: "1vh",
        color: "#FF8C00"
    },
    cardBody: {
        display: "flex"
    },
    firstDivider:{
        width:"100%"
    },
    secondDivider:{
        width:"113%"
    }
});

export { wallboardStyles };