import ReactDOM from 'react-dom/client';
import './index.css';
import WallboardApp from './WallboardApp';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <FluentProvider theme={teamsLightTheme}>
      <WallboardApp />
    </FluentProvider>
  </BrowserRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
