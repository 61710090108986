import axios from "axios";
import SessionStorageHelper from "./SessionStorageHelper";
// import { message } from 'antd'
import { Auth } from "aws-amplify";

const sessionStorageHelper = SessionStorageHelper.getInstance();
// var qs = require('qs');
const HTTPClient = axios.create();
const delay = ms => new Promise(res => setTimeout(res, ms));

// Add a request interceptor
HTTPClient.interceptors.request.use(

  (config) => {
    try {
      const token = sessionStorageHelper.getIDTokenId();
      if (token) {
        config.headers["Authorization"] = token;
        // config.headers["Authorization"] = `Bearer ${token},UserPoolId ${process.env.REACT_APP_ADMIN_CONSOLE_USER_POOL_ID}`;
      }
      return config;
    } catch (e) {
      console.log("error in reading session,", e)
    }
  },
  (error) => {
    console.log('HTTP Request error', error)
    Promise.reject(error);
  }
);

//Add a response interceptor
HTTPClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (JSON.parse(JSON.stringify(error.response.status)) && JSON.parse(JSON.stringify(error.response.status)) === 400) {
      return error.response
    }
    console.log("Error in HTTPClient response interceptor - AUTH Failure/Token Expiry", error);
    console.log('error.response', JSON.parse(JSON.stringify(error.response)))
    const originalRequest = error.config;
    if (!originalRequest._retry) {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
          sessionStorageHelper.setToken(session)
          originalRequest._retry = true;
          originalRequest.headers.Authorization = sessionStorageHelper.getIDTokenId()
          return HTTPClient(originalRequest)
        });
      } catch (e) {
        console.log('Unable to refresh Token', e);
        return Promise.reject(error);
      }

    } else {
      if (sessionStorageHelper.getRetryErrorFlag() === 0) {
        sessionStorageHelper.setRetryErrorFlag(1);
        // message.error({
        //   content:
        //     'Your not authorized to view this page. Please contact administrator for access',
        //   className: 'message-failure'
        // })
        console.log('error', error)
      }
      await delay(3000)
      window.location.pathname = "/logout";
      return Promise.reject(error);
    }
  }
);

export default HTTPClient;
